import React from 'react';
// import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';

const Header = () => (
  <header className="sticky top-0 bg-white shadow z-10">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <a href="/" className="flex items-center text-2xl">
        {/* <div className="w-12 mr-3">
          <LogoIcon />
        </div> */}
        GitGlow
      </a>
      <div className="flex mt-4 sm:mt-0">
        {/* <a className="px-4" href="#features">
          Features
        </a> */}
        {/* <a className="px-4" href="#services">
          Services
        </a>
        <a className="px-4" href="#stats">
          Stats
        </a>
        <a className="px-4" href="#testimonials">
          Testimonials
        </a> */}
      </div>
      <div className="hidden md:block">
        <ButtonLink />
      </div>
    </div>
  </header>
);

const ButtonLink = () => {
  return <a className="bg-blue-500 hover:bg-blue-800 py-3 px-8 rounded text-white"
    href="https://github.com/apps/public-repository-alerts/installations/new"
  >Install via the GitHub Marketplace</a>
}

export default Header;
