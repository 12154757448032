import React from 'react';

const Footer = () => (
  <footer className=" mx-auto py-16 px-3 mt-48 mb-8z text-gray-800 bg-gray-200">
    <div className="container flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">About Us</h2>
        <p className="mt-5">GitGlow enables you to act fast when repositories become public.</p>
      </div>

      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          {/* <li>
            <a href="https://gitglow.com">Terms &amp; Conditions</a>
          </li> */}
          <li>
            <a href="/privacy">Privacy Policy</a>
          </li>
        </ul>
      </div>

      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Social Media</h2>
        <ul className="mt-4 leading-loose">
          {/* <li>
            <a href="https://dev.to/changoman">gitglow.com</a>
          </li> */}

          <li className="hover:text-blue-500">
            <a target="_blank" rel="noreferrer" href="https://twitter.com/GitGlow">Twitter</a>
          </li>

          <li className="hover:text-blue-500">
            <a target="_blank" rel="noreferrer" href="https://github.com/GitGlowApp">GitHub</a>
          </li>
        </ul>
      </div>
    </div>

  </footer>
);

export default Footer;
